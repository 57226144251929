const de = {
  btn: {
    registration: "Anmeldung",
    openAccount: "Offener Account",
    join: "Verbinden",
    login: "Betreten",
    register: "Registrieren",
    continue: "Weitermachen",
    begin: "Starten Sie den Handel",
    more: "Mehr Details",
    confirm: "Bestätigen",
  },
  menu: {
    home: "Heim",
    markets: "Märkte",
    about: "Über uns",
    contacts: "Kontakte",
    platform: "Plattform",
    faq: "FAQ",
    safe: "Sicherheit",
  },
  subMenu: {
    forex: "Forex",
    indices: " Indizes",
    stock: "Aktie",
    currency: "Kryptowährungen",
    commodities: "Rohstoffe",
  },
  form: {
    name: "Name",
    phone: "Telefonnummer",
  },
  privacy: "Datenschutzrichtlinie",
  terms: "Allgemeine Geschäftsbedingungen",
  assets: "Vermögenswerte",
  support: "Unterstützung",
  leverageTo: "Schulter hoch",
  depositFrom: "Einzahlung ab",
  cities: ["Großbritannien", "Zypern", "Hongkong"],
  popularAssets: "Beliebte Vermögenswerte",
  worksDay1: "Mo-Fr",
  worksDay2: "Sa-So",
  ourAddress: "Unsere Adresse",
  company: "Unternehmen",
};

export default de;
